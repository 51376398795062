<template>
  <div class="goods">
    <div class="goods-item" v-for="item in list" :key="item.id" @click="tolink('shopDetails?id=' + item.id)">
      <img :src="getimg(item.img)" class="goods-img" />
      <div class="goods-content">
        <slot :slotValue="item">
          <div class="goods-name">{{ item.name }}</div>
          <div class="goods-label">{{ item.label }}</div>
          <div class="goods-price">￥{{ item.price }}</div>
          <!-- <div class="goods-tag">贡献值: $955.5(¥6497.4)</div> -->
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    autoToDetails: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
    }
  },
  methods: {
    tolink(path) {
      if(this.autoToDetails) {
        this.$router.push(path)
      } else {
        this.$emit('click')
      }
    },
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "Upload/" + img;
			}
		}
  }
}
</script>

<style scoped>
.goods {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.goods-item {
  margin-bottom: 10px;
  width: 48%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
}

.goods-content {
  padding: 0 12px 10px;
}

.goods-price {
  color: rgba(255, 45, 46, 1);
  font-size: 18px;
}

.goods-img {
  width: 100%;
  height: 170px;
}

.goods-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: rgba(0, 0, 0, 1);
}

.goods-label {
  height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: gray;
  font-size: 12px;
}

.goods-tag {
  padding: 0 5px;
  color: rgba(255, 45, 46, 1);
  border: 1px solid rgba(255, 45, 46, 1);
  font-size: 12px;
}
</style>
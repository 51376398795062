<template>
  <div class="store">
    <div class="header" :style="style">
      <div class="header-content">
        <div class="nav">
          <q-icon name="arrow_back_ios" color="white" size="16px" @click="back"></q-icon>
          <q-icon name="more_horiz" color="white" size="16px"></q-icon>
        </div>
        <div class="info">
          <img :src="getimg(store.shopImg)" width="76" />
          <div class="col q-ml-md">
            <div class="row justify-between items-center">
              <div class="info-title">{{ store.shopName }}</div>
              <img src="@/assets/img/shop/white-sc.png" width="20" height="18" />
            </div>
            <div class="row items-center q-mt-sm">
              <img src="@/assets/img/shop/store-address.png" width="11" height="15" />
              <div class="address">{{ store.shopSheng + store.shopShi + store.shopXian + store.shopAddress }}</div>
            </div>
          </div>
        </div>
        <div class="num">商品数 {{ goodslist.length }}</div>
      </div>
    </div>

    <div class="tabs">
      <van-tabs v-model:active="active" @change="tabsChange">
        <van-tab v-for="(item,index) in tabs" :key="index" @click="sortClick">
          <template #title>
            <div class="row items-center">
              {{ item.text }}
              <!-- <div class="row items-center" v-if="item.sort">
                <img src="@/assets/img/shop/zhengxu.png" width="15" height="15" v-if="item.sortState == 1" />
                <img src="@/assets/img/shop/daoxu.png" width="15" height="15" v-if="item.sortState == 2" />
              </div> -->
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </div>

    <div class="containter q-mt-sm">
      <div class="goods-wrap">
        <van-list v-if="goodslist.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false" offset="10"
          finished-text="没有更多了" @load="onLoad">
          <GoodsList :list="goodslist" />
        </van-list>
        <van-empty description="暂无商品" v-if="goodslist.length == 0" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Tab, Tabs, List, Empty } from 'vant';
import GoodsList from './components/GoodsList.vue'

export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [Empty.name]: Empty,
    GoodsList
  },
  setup() {
    return {
      id: ref(""),
      store: ref({}),
      style: ref({}),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
      goodslist: ref([]),
      active: ref(0),
      tabs: [
        {text: '推荐'},
        {text: '新品'},
        {text: '销量', sort: true, sortState: 1},
        {text: '价格', sort: true, sortState: 1},
      ]
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getdata()
    this.getGoodsList()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getGoodsList()
    },
    back() {
      this.$router.back()
    },
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "Upload/" + img;
			}
		},
    tabsChange() {
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.goodslist = []
      this.getGoodsList()
    },
    // 销量 / 价格 正序倒序
    sortClick() {
      console.log(this.active)
    },
    // 排序
    listSort() {
      if(this.active == 1) {
        this.goodslist.sort((a, b) => {
          return new Date(b.cdate).getTime() - new Date(a.cdate).getTime()
        })
      }
      if(this.active == 2) {
        this.goodslist.sort((a, b) => {
          return b.sales - a.sales
        })
      }
      if(this.active == 3) {
        this.goodslist.sort((a, b) => {
          return  b.price - a.price
        })
      }
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/ShopGoods/GetShopUserInfo",
        {
          shopUserid: _this.id
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            _this.store = data
          }
        }
      )
    },
    getGoodsList() {
      let _this = this
      _this.$request.post(
        "api/ShopGoods/GetShopUserList",
        {
          shopUserid: _this.id,
          pageIndex: _this.pageIndex,
          pageSize: 10
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            data.forEach(item => {
              item.img = item.dbShopGoodsModel[0].img
              item.price = item.dbShopGoodsModel[0].price
              item.sales = item.dbShopGoodsModel[0].sales
            })
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            _this.goodslist.push(...data)
            if(_this.goodslist.length > 0) {
              let goods = _this.goodslist[0]
              _this.style.backgroundImage = `url(${_this.getimg(goods.img)})`
            }
            _this.listSort()
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.store {
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.header {
  position: relative;
  height: 190px;
  background-size: cover;
  background-position: center;
}

.header::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

.header-content {
  position: relative;
  padding: 0 16px;
}

.nav {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.info-title {
  font-size: 18px;
  color: #fff;
}

.address {
  margin-left: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 12px;
  color: #fff;
}

.num {
  margin-top: 15px;
  font-size: 12px;
  color: #fff;
}
</style>